var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"button"},[_c('button',_vm._b({staticClass:"inline-flex btn cursor-pointer items-center leading-7 border-transparent shadow-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500",class:{
			'text-white bg-blue-500 hover:bg-blue-600': _vm.color === 'blue',
			'px-4 py-2 text-sm': _vm.size === 'smoll',
			'px-12 py-2.5 text-md': _vm.size === 'medium',
			'px-12 py-2.5 text-md w-full justify-center': _vm.size === 'full',
			'text-textGray border-textGray pointer-events-none': _vm.disabled,
			'text-blue-500 bg-white border-blue-500 hover:bg-blue-500 hover:text-white': _vm.color === 'white',
			'border-2': _vm.border === 'thick',
			border: _vm.border === ''
		},attrs:{"disabled":_vm.disabled},on:{"click":function($event){return _vm.$emit('click')}}},'button',_vm.$attrs,false),[(_vm.load)?_c('svg',{staticClass:"spinner -ml-1 mr-2 h-5 w-5",attrs:{"viewBox":"0 0 66 66","xmlns":"http://www.w3.org/2000/svg"}},[_c('circle',{staticClass:"path",attrs:{"fill":"none","stroke":_vm.spinnerColor,"stroke-width":"6","stroke-linecap":"round","cx":"33","cy":"33","r":"30"}})]):(_vm.icon)?_c('Icons',{staticClass:"-ml-1 mr-2",class:{ white: _vm.color === 'white' },attrs:{"icon-name":_vm.icon}}):_vm._e(),_c('p',[_vm._v(_vm._s(_vm.text))])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }