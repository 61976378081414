<template>
	<div class="pagination hidden sm:flex flex-row pt-6 pl-6 pr-20 pb-12 text-gray-400 text-sm">
		<div class="mr-auto">
			<p>Всего записей: {{ rowsCount }}</p>
		</div>
		<div class="flex flex-row ml-auto">
			<p>Строк на странице:</p>
			<ul class="flex flex-row">
				<li @click="updateRow(50)" class="flex ml-4 cursor-pointer" :class="{ 'text-blue-500': pageSize == 50 }">50</li>
				<li @click="updateRow(100)" class="flex ml-4 cursor-pointer" :class="{ 'text-blue-500': pageSize == 100 }">100</li>
				<li @click="updateRow(200)" class="flex ml-4 cursor-pointer" :class="{ 'text-blue-500': pageSize == 200 }">200</li>
				<li @click="updateRow(null)" class="flex ml-4 cursor-pointer" :class="{ 'text-blue-500': pageSize == null }">Все</li>
			</ul>
		</div>
		<div class="flex flex-row ml-24">
			<ul class="flex flex-row">
				<li class="flex ml-4 cursor-pointer arrow" @click="prevPage" />
				<li class="flex">
					<span>Стр</span>
					<b class="ml-2">{{ pageNumber + 1 }}</b>
					<span class="ml-2">из</span>
					<b class="ml-2">{{ pagesCount === 0 ? 1 : pagesCount }}</b>
				</li>
				<li class="flex ml-2 cursor-pointer arrow" @click="nextPage" />
			</ul>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Paginations',
	props: {
		rowsCount: Number,
		pageSize: Number,
		pageNumber: Number,
		pagesCount: Number
	},
	methods: {
		updateRow(pageSize) {
			this.$emit('query', {
				pageSize: pageSize,
				pageNumber: this.pageNumber
			});
		},
		prevPage() {
			if (this.pageNumber > 0) {
				this.$emit('query', {
					pageSize: this.pageSize,
					pageNumber: this.pageNumber - 1
				});
			}
		},
		nextPage() {
			if (this.pageNumber < this.pagesCount - 1) {
				this.$emit('query', {
					pageSize: this.pageSize,
					pageNumber: this.pageNumber + 1
				});
			}
		}
	}
};
</script>

<style scoped>
.pagination {
	white-space: nowrap;
	background: #fff;
	border-radius: 0 0 10px 10px;
	box-shadow: 0px 4px 17px rgba(171, 184, 225, 0.4);
}
li.arrow::before {
	@apply block w-2 h-2 mt-1.5 mr-2 border-b-2 border-l-2 border-gray-400;
	content: '';
	transform: rotate(45deg);
}
.arrow:last-child::before {
	transform: rotate(-135deg);
}
</style>
