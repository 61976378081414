// Если нам надо оплучить глобальные данные
// import { getters } from '@/store/store';
// Если нам надо обработать данные используем мутации
// import { mutations } from './mutations';

export default {
	getUsersActivity(parametrs) {
		return new Promise((resolve, reject) => {
			this.$http
				.post('MedicalOrgs/GetUsersActivity', parametrs)
				.then((res) => {
					resolve(res);
				})
				.catch((error) => {
					reject(error);
				});
		});
	}
};
