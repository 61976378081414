<template>
	<div>
		<div class="flex flex-col relative">
			<div class="h-auto filter mb-5">
				<div class="w-full rounded-lg flex flex-wrap items-center justify-between gap-5">
					<h1 class="text-xl h-6 font-extrabold sm:text-2xl">Активность пользователей</h1>
					<div class="flex items-center justify-end gap-5">
						<Button text="Экспорт в Excel" icon="exel" @click.native="downloadExcell(params)" :disabled="loadToExcell" :load="loadToExcell" />
					</div>
				</div>
			</div>
			<div class="flex flex-col relative">
				<div class="t-overflow rounded-t-lg overflow-x-auto h-auto bg-white filter shadow-default" :style="data.length > 14 ? 'height: calc(100vh - 320px)' : ''">
					<table class="w-full">
						<thead>
							<tr :class="{ tr1__sticky: params.pageSize > 14 }">
								<th class="w-14 text-center border-b border-r border-gray-200 text-sm text-left leading-4 font-semibold text-gray-500 tracking-wider">П/П</th>
								<th
									v-for="(item, ind) in fields"
									:key="'J' + ind"
									class="px-6 py-4 border-b border-r border-gray-200 text-sm text-left leading-4 font-semibold text-gray-500 tracking-wider"
								>
									<span class="flex relative">
										<span class="border-b border-gray-200 cursor-pointer" :class="{ 'text-blue-500 underline': filterBy === ind }" @click="switchFilterVisibility(ind)">
											{{ item.Name }}
										</span>
										<Icons v-if="params.filters.find((f) => f.fieldName === item.fieldName)" icon-name="filter" class="hidden sm:block ml-2" />
										<FilterComponent
											v-on:change="switchFilterVisibility(null)"
											:position="ind <= 1 ? 'left' : 'right'"
											:filterOpen="filterOpen"
											:id-filter="ind"
											:param="params"
											@query="getData"
											:fields="fields"
											:item="item"
										/>
									</span>
								</th>
								<th class="px-6 py-4 border-b border-r border-gray-200 text-left text-sm leading-4 font-semibold text-gray-500 tracking-wider">
									<span class="relative">
										<span> Действие </span>
									</span>
								</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(item, indx) in data" :key="'A' + indx">
								<td class="text-center font-normal text-sm whitespace-no-wrap border-b border-r text-left border-gray-200 leading-5 text-gray-500">
									<span v-if="params.currentPage === 0">
										{{ indx + 1 }}
									</span>
									<span v-if="params.currentPage > 0">
										{{ params.currentPage * params.pageSize + (indx + 1) }}
									</span>
								</td>
								<td class="px-6 py-4 font-normal text-sm whitespace-no-wrap border-b border-r text-left border-gray-200 leading-5 text-gray-500">
									<span>
										{{ item.region }}
									</span>
								</td>
								<td class="px-6 py-4 font-normal text-sm whitespace-no-wrap border-b border-r text-left border-gray-200 leading-5 text-gray-500">
									<span>
										{{ item.login }}
									</span>
								</td>
								<td class="px-6 py-4 font-normal text-sm whitespace-no-wrap border-b border-r text-left border-gray-200 leading-5 text-gray-500">
									<span>
										{{ item.ipAddress }}
									</span>
								</td>
								<td class="px-6 py-4 font-normal text-sm whitespace-no-wrap border-b border-r text-left border-gray-200 leading-5 text-gray-500">
									<span>
										{{ item.pathRF }}
									</span>
								</td>
								<td class="px-6 py-4 font-normal text-sm whitespace-no-wrap border-b border-r text-left border-gray-200 leading-5 text-gray-500">
									<span>
										{{ item.created | moment('DD.MM.YYYY HH:mm:ss') }}
									</span>
								</td>
								<td class="px-6 py-4 text-sm text-left whitespace-no-wrap border-b border-r border-gray-200 leading-5">
									<button class="text-dopBlue font-semibold underline" @click="openPopupDetails(item, indx)">Просмотр</button>
								</td>
							</tr>
						</tbody>
					</table>
					<div v-if="!loadingTable && data.length === 0" class="flex items-center w-full h-96 whitespace-no-wrap text-xl leading-5 text-gray-500 text-center">
						<span class="w-full">Данных нет...</span>
					</div>
				</div>
				<!-- Навигация -->
				<div class="h-auto rounded-b-lg bg-white filter shadow-default">
					<Pagination :rowsCount="totalRows" :pageSize="params.pageSize" :pageNumber="params.currentPage" :pagesCount="totalCount" @query="updatePage" />
				</div>
				<!-- Прелоудер -->
				<div v-if="loadingTable" class="absolute w-full h-full preloader rounded-lg">
					<loadingComponent class="absolute top-1/2 left-1/2" />
				</div>
			</div>
		</div>
		<PopUp :open="openPopup" size="763px" title="Просмотр данных" @close="closePopup()">
			<div class="text-gray-500 text-sm font-normal leading-6 mb-10">
				<div>{{ itemDetails.jsonStr }}</div>
			</div>
			<div class="flex mt-5">
				<Button size="medium" text="Закрыть" @click.native="closePopup()" />
			</div>
		</PopUp>
	</div>
</template>

<script>
import FilterComponent from '@/components/Filter.vue';
import Pagination from '@/components/Pagination.vue';
import loadingComponent from '@/components/Loading.vue';
import Button from '@/components/Button.vue';
import PopUp from '@/components/PopUp.vue';
import Icons from '@/components/Icons.vue';
import actionsL from '../store/actions';
import { actions } from '@/store/store';

export default {
	name: 'TableUserActivity',
	components: {
		loadingComponent,
		FilterComponent,
		Pagination,
		Button,
		Icons,
		PopUp
	},
	data() {
		return {
			openPopup: false,
			loadToExcell: false,
			loadingTable: false,
			filterOpen: null,
			filterBy: null,
			totalRows: 0,
			totalCount: 0,
			page: 0,
			params: {
				filters: [],
				sortOption: {
					fieldName: '',
					sortType: null
				},
				columns: [],
				pageSize: this.$DefaultPageSize,
				currentPage: 0
			},
			fields: [
				{
					Name: 'Название региона',
					fieldName: 'region',
					filterType: 2,
					filterValueType: 1,
					autocomplete: true,
					list: [],
					widthStroke: 'w-96',
					heightUl: '150px',
					itemKey: 'Name',
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию (А-Я)',
					label2: 'По убыванию (Я-А)'
				},
				{
					Name: 'Логин',
					fieldName: 'login',
					filterType: 2,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'IP адрес',
					fieldName: 'ipAddress',
					filterType: 2,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Путь',
					fieldName: 'pathRF',
					filterType: 2,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Дата и время действия',
					fieldName: 'created',
					filterType: 3,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				}
			],
			data: [],
			itemDetails: {}
		};
	},
	methods: {
		...actionsL,
		getRegions: actions.getRegions,
		downloadExcell(params) {
			this.loadToExcell = true;

			this.$http
				.post('MedicalOrgs/GetUsersActivityExcel', params, {
					responseType: 'blob',
					headers: {
						'Access-Control-Expose-Headers': 'Content-Disposition'
					}
				})
				.then((res) => {
					let fileNameAndType = decodeURI('Активность пользователей.xlsx');

					const url = window.URL.createObjectURL(new Blob([res.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', fileNameAndType);
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
					this.loadToExcell = false;
				})
				.catch((error) => {
					if (error) {
						this.loadToExcell = false;
						this.showNotification('Ошибка выгрузки, обратитесь к Администратору!', 'error');
					}
				});
		},
		updatePage(input) {
			this.params.pageSize = input.pageSize;
			this.params.currentPage = input.pageNumber;
			this.getData(this.params, false);
		},
		getData(params, flag) {
			this.loadingTable = true;
			// если фильтруем/сортируем то возвращаем пагинацию на 1 страницу
			if (flag) {
				this.params.currentPage = 0;
				this.page = 12;
			}

			this.getUsersActivity(params).then((res) => {
				this.data = res.data.data;
				this.totalRows = res.data.totalRecords;
				this.totalCount = params.pageSize ? Math.ceil(res.data.totalRecords / params.pageSize) : 1;
				this.loadingTable = false;
			});
		},
		switchFilterVisibility(key) {
			this.filterData = {
				sortBy: 'asc',
				searchValue: ''
			};
			this.filterOpen = this.filterOpen === key ? null : key;
			this.filterBy = key;
		},
		openPopupDetails(item, index) {
			this.index = index;
			this.openPopup = true;
			this.itemDetails = item;
		},
		closePopup() {
			this.index = null;
			this.openPopup = false;
		},
		showNotification(text, type) {
			this.$toast[type](text, {
				position: 'top-right',
				timeout: 3000,
				closeOnClick: true,
				pauseOnFocusLoss: true,
				pauseOnHover: true,
				draggable: true,
				draggablePercent: 0.6,
				showCloseButtonOnHover: false,
				hideProgressBar: true,
				closeButton: 'button',
				icon: true,
				rtl: false
			});
		}
	},
	mounted() {
		this.getRegions().then((result) => {
			this.fields[0].list = result.data;
		});

		this.getData(this.params, false);
	}
};
</script>

<style scoped>
.tr1__sticky {
	background-color: #f9fafb;
	top: 0;
	position: sticky;
	z-index: 7;
}
.preloader {
	z-index: 10;
	background: rgba(0, 0, 0, 0.09);
}
</style>
