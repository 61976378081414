<template>
	<div class="flex flex-col w-screen sm:w-auto px-4 py-6 sm:px-6 sm:py-6">
		<TableUserActivity />
	</div>
</template>

<script>
import TableUserActivity from './TableUserActivity.vue';

export default {
	name: 'Main',
	components: { TableUserActivity }
};
</script>
