<template>
	<div class="popup-wrapp" v-if="open">
		<transition name="modal">
			<div class="popup" :style="{ width: size }">
				<div class="popup-header">
					<h3>{{ title ? title : '' }}</h3>
					<Icons class="cursor-pointer" @click.native="$emit('close')" icon-name="close" />
				</div>
				<div class="popup-content">
					<slot></slot>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
import Icons from '@/components/Icons.vue';

export default {
	name: 'PopUp',
	components: { Icons },
	props: {
		title: String,
		open: Boolean,
		size: {
			type: String,
			default: '763px'
		}
	}
};
</script>

<style scoped>
.popup-wrapp {
	position: fixed;
	max-height: 100%;
	overflow: auto;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 9999;
	background: rgba(106, 110, 131, 0.5);
	display: flex;
}

.popup {
	margin: auto;
	border-radius: 10px;
	background: #fff;
}

.popup-header {
	display: flex;
	padding: 25px 32px;
	align-items: center;
	background: #f2f5ff;
	justify-content: space-between;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
}

.popup-header h3 {
	font-weight: 800;
	font-size: 18px;
	line-height: 19px;
	color: #35363d;
}

.popup-content {
	padding: 30px 40px;
}
</style>
